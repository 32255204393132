import React from 'react';

import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { InView } from 'react-intersection-observer';

import './list-item.scss';

const ListItem = ( { data } ) => {
      
    const { title, slug, shortDescription, thumbnail, type } = data;

    const image = getImage( thumbnail );

    const renderImage = ( image ) => {
        if ( image ) {
            return <GatsbyImage image={image} alt={title} objectFit="contain" />;
        }
    };

    const renderItem = () => {
        if ( type === 'compact' ) {
            return <Link to={`/${slug}`} className="list-item-content">
                {renderColumns()}
            </Link>;
        } else if ( type === 'extended' ) {
            return <div className="list-item-content">
                {renderColumns()}
            </div>;
        }
    };

    const renderColumns = () => {
        return <>
            <div className="column-left">
                {renderImage( image )}
                <div className="title">
                    <h3>{title}</h3>
                </div>
            </div>
            <div className="column-right">
                <div className="description">
                    {type === 'compact' &&
                    <p dangerouslySetInnerHTML={{
                        __html: ( shortDescription )
                    }}> 
                    </p>}
                    {type === 'extended' &&
                    <div dangerouslySetInnerHTML={{
                        __html: ( shortDescription )
                    }}> 
                    </div>}
                </div>
            </div>
        </>;
    };

    return <InView>
        {( { inView, ref } ) => (
            <li className={`list-item list-item-type-${type} ${inView ? 'is-inview' : ''}`} ref={ref}>
                {renderItem()}
            </li> )}
    </InView>;

};

export default ListItem;
